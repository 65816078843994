import React from "react";
import "./NotFound.scss";
import "../../theme/style.scss";

import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  // HANDLE BACK
  const theme = useTheme();
  const navigate = useNavigate();

  // BREAK POINTS
  // const xs = useMediaQuery(theme.breakpoints.up("xs")); //-- FOR REF PLEASE ONLY TAKE THEME BREAK POINTS FOR MEDIA QUERY
  // const sm = useMediaQuery(theme.breakpoints.up("sm"));
  // const md = useMediaQuery(theme.breakpoints.up("md"));
  // const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const mdd = useMediaQuery(theme.breakpoints.down("md"));
  const lgd = useMediaQuery(theme.breakpoints.down("lg"));

  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <Box className="main_container">
        <Grid container spacing={4}>
          <Grid
            item
            md={1}
            lg={1}
            xl={2}
            display={{
              xs: "none",
              xl: "grid",
              lg: "grid",
              md: "grid",
            }}
          ></Grid>
          <Grid
            item
            xs={10}
            sm={6}
            md={5}
            lg={5}
            xl={4}
            className={`${mdd ? "width-mdd" : ""}`}
          >
            <img
              src="/not-found.png"
              alt="NotFound Image"
              className="not_found_img"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
            <Box className={`inner_right_box`}>
              <Typography
                className={`page_not_found_msg ${
                  mdd ? "font-size-24" : "font-size-40"
                } bolder`}
              >
                Page Not Found
              </Typography>
              <Typography
                className={`not_found_msg ${mdd ? "" : "font-size-18"}`}
              >
                The Page you are looking for doesn't exist or an error occurred,{" "}
              </Typography>
              <Typography
                className={`not_found_msg ${mdd ? "" : "font-size-18"}`}
              >
                Go back or head over to{" "}
                <a
                  href="https://hcp.vista-med.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="web_link"
                >
                  Vista-med.com
                </a>{" "}
                to choose new direction.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className="primary-button"
                size="large"
                sx={{ my: 2 }}
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
              >
                Back to Home
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xl={2}
            display={{
              xs: "none",
              xl: "grid",
            }}
          ></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NotFound;
