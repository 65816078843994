// IMPORT REACT
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import NavBar from "./_components/Navbar/NavBar";
import LoadingFallback from "../components/Fallback/LoadingFallback";

import NotFound from "../pages/NotFound/NotFound";
import App from "../App";
import InvestigationSampleCollectedFromList from "../pages/Investigation/InvestigationSampleCollectedFrom/InvestigationSampleCollectedFromList";
import InvestigationSampleCollectedFromSave from "../pages/Investigation/InvestigationSampleCollectedFrom/InvestigationSampleCollectedFromSave";

const SignInCover = React.lazy(
  () => import("../pages/SignInCover/SignInCover")
);
const SignIn = React.lazy(() => import("../pages/SignIn/SignIn"));
const SignUp = React.lazy(() => import("../pages/SignUp/SignUp"));
const ThankYou = React.lazy(() => import("../pages/ThankYou/ThankYou"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const Master = React.lazy(() => import("../pages/Master/Master"));
const ResetPassword = React.lazy(
  () => import("../pages/Resetpassword/Resetpassword")
);
const ForgotPassword = React.lazy(
  () => import("../pages/Forgotpassword/Forgotpassword")
);
const ManageUser = React.lazy(
  () => import("../pages/Team/Users/ManageUser")
);
const Settings = React.lazy(() => import("../pages/Settings/Settings"));
const Help = React.lazy(() => import("../pages/Help/Help"));
const BranchList = React.lazy(() => import("../pages/Branch/BranchList"));
const BranchSave = React.lazy(() => import("../pages/Branch/BranchSave"));
const DepartmentList = React.lazy(
  () => import("../pages/Department/DepartmentList")
);
const DepartmentSave = React.lazy(
  () => import("../pages/Department/DepartmentSave")
);
const MedicineDoseList = React.lazy(
  () => import("../pages/Medicine/MedicineDose/MedicineDoseList")
);
const MedicineDoseSave = React.lazy(
  () => import("../pages/Medicine/MedicineDose/MedicineDoseSave")
);
const FollowUpMasterList = React.lazy(
  () => import("../pages/FollowUp/FollowUpMaster/FollowUpMasterList")
);
const FollowUpMasterSave = React.lazy(
  () => import("../pages/FollowUp/FollowUpMaster/FollowUpMasterSave")
);
const FollowUpReasonList = React.lazy(
  () => import("../pages/FollowUp/FollowUpReason/FollowUpReasonList")
);
const FollowUpReasonSave = React.lazy(
  () => import("../pages/FollowUp/FollowUpReason/FollowUpReasonSave")
);
const FollowUpInstructionList = React.lazy(
  () => import("../pages/FollowUp/FollowUpInstruction/FollowUpInstructionList")
);
const FollowUpInstructionSave = React.lazy(
  () => import("../pages/FollowUp/FollowUpInstruction/FollowUpInstructionSave")
);
const ConsumerList = React.lazy(() => import("../pages/Consumer/ConsumerList"));
const ConsumerSave = React.lazy(() => import("../pages/Consumer/ConsumerSave"));
const OrganizationSave = React.lazy(
  () => import("../pages/Organization/OraganizationSave")
);
const OrganizationList = React.lazy(
  () => import("../pages/Organization/OrganizationList")
);
const DiagnosisMasterSave = React.lazy(
  () => import("../pages/Diagnosis/DiagnosisMaster/DiagnosisMasterSave")
);
const DiagnosisMasterList = React.lazy(
  () => import("../pages/Diagnosis/DiagnosisMaster/DiagnosisMasterList")
);
const FollowUpDurationSave = React.lazy(
  () => import("../pages/FollowUp/FollowUpDuration/FollowUpDurationSave")
);
const FollowUpDurationList = React.lazy(
  () => import("../pages/FollowUp/FollowUpDuration/FollowUpDurationList")
);
const DiagnosisTypeList = React.lazy(
  () => import("../pages/Diagnosis/DiagnosisType/DiagnosisTypeList")
);
const DiagnosisTypeSave = React.lazy(
  () => import("../pages/Diagnosis/DiagnosisType/DiagnosisTypeSave")
);
const ICDCodeList = React.lazy(
  () => import("../pages/Diagnosis/ICDCodes/ICDCodeList")
);

const ICDCodeSave = React.lazy(
  () => import("../pages/Diagnosis/ICDCodes/ICDCodeSave")
);

const InstructionList = React.lazy(
  () => import("../pages/Instruction/InstructionList")
);
const InstructionSave = React.lazy(
  () => import("../pages/Instruction/InstructionSave")
);

const QuickNotesList = React.lazy(
  () => import("../pages/QuickNotes/QuickNotesList")
);

const QuickNotesSave = React.lazy(
  () => import("../pages/QuickNotes/QuickNotesSave")
);

const InvestigationMasterList = React.lazy(
  () =>
    import("../pages/Investigation/InvestigationMaster/InvestigationMasterList")
);
const InvestigationMasterSave = React.lazy(
  () =>
    import("../pages/Investigation/InvestigationMaster/InvestigationMasterSave")
);
const MedicineTemplateList = React.lazy(
  () => import("../pages/Template/MedicineTemplate/MedicineTemplateList")
);
const MedicineGenericNameList = React.lazy(
  () => import("../pages/Medicine/MedicineGenericName/MedicineGenericNameList")
);
const MedicineGenericNameSave = React.lazy(
  () => import("../pages/Medicine/MedicineGenericName/MedicineGenericNameSave")
);
const MedicinecompanyList = React.lazy(
  () => import("../pages/Medicine/Medicinecompany/MedicinecompanyList")
);
const MedicinecompanySave = React.lazy(
  () => import("../pages/Medicine/Medicinecompany/MedicinecompanySave")
);
const MedicineGroupList = React.lazy(
  () => import("../pages/Medicine/MedicineGroup/MedicineGroupList")
);
const MedicineGroupSave = React.lazy(
  () => import("../pages/Medicine/MedicineGroup/MedicineGroupSave")
);
const MedicineRouteOfAdministrationList = React.lazy(
  () => import("../pages/Medicine/MedicineRouteofAdministration/MedicinerouteofadministrationList")
);
const MedicineRouteOfAdministrationSave = React.lazy(
  () => import("../pages/Medicine/MedicineRouteofAdministration/MedicinerouteofadministrationSave")
);
const MedicineRelationtoFoodList = React.lazy(
  () => import("../pages/Medicine/MedicineRelationtoFood/MedicineRelationtoFoodList")
);
const MedicineRelationtoFoodListSave = React.lazy(
  () => import("../pages/Medicine/MedicineRelationtoFood/MedicineRelationtoFoodSave")
);
const MedicineIndicationforSOSmedicineList = React.lazy(
  () => import("../pages/Medicine/MedicineIndicationforSOSmedicine/MedicineIndicationforSOSmedicineList")
);
const MedicineIndicationforSOSmedicineSave = React.lazy(
  () => import("../pages/Medicine/MedicineIndicationforSOSmedicine/MedicineIndicationforSOSmedicineSave")
);


const MedicineFormulationList = React.lazy(
  () => import("../pages/Medicine/MedicineFormulation/MedicineFormulationList")
);
const MedicineFormulationSave = React.lazy(
  () => import("../pages/Medicine/MedicineFormulation/MedicineFormulationSave")
);
const MedicineUsedAsList = React.lazy(
  () => import("../pages/Medicine/MedicineUsedAs/MedicineUsedAsList")
);
const MedicineUsedAsSave = React.lazy(
  () => import("../pages/Medicine/MedicineUsedAs/MedicineUsedAsSave")
);
const MedicineDiluentNameList = React.lazy(
  () => import("../pages/Medicine/MedicineDiluentName/MedicineDiluentNameList")
);
const MedicineDiluentNameSave = React.lazy(
  () => import("../pages/Medicine/MedicineDiluentName/MedicineDiluentNameSave")
);
const MedicineTemplateSave = React.lazy(
  () => import("../pages/Template/MedicineTemplate/MedicineTemplateSave")
);
const DiagnosisTemplateList = React.lazy(
  () => import("../pages/Template/DiagnosisTemplate/DiagnosisTemplateList")
);
const DiagnosisTemplateSave = React.lazy(
  () => import("../pages/Template/DiagnosisTemplate/DiagnosisTemplateSave")
);
const InvestigationTemplateSave = React.lazy(
  () =>
    import("../pages/Template/InvestigationTemplate/InvestigationTemplateSave")
);
const InvestigationTemplateList = React.lazy(
  () =>
    import("../pages/Template/InvestigationTemplate/InvestigationTemplateList")
);
const LaboratoryList = React.lazy(
  () => import("../pages/Laboratory/LaboratoryList")
);
const LaboratorySave = React.lazy(
  () => import("../pages/Laboratory/LaboratorySave")
);
const HospitalList = React.lazy(
  () => import("../pages/Hospital/HospitalList")
);
const HospitalSave = React.lazy(
  () => import("../pages/Hospital/HospitalSave")
);
const RefOPDExaminationCerebellarSignsList = React.lazy(
  () => import("../pages/Examiantion/RefOPDExamiantionCerebellarSigns/RefOPDExamiantionCerebellarSignsList")
);
const RefOPDExaminationCerebellarSignsSave = React.lazy(
  () => import("../pages/Examiantion/RefOPDExamiantionCerebellarSigns/RefOPDExamiantionCerebellarSignsSave")
);
const RefOPDExamiationResponseToReflexList = React.lazy(
  () => import("../pages/Examiantion/RefOPDExamiationResponseToReflex/RefOPDExamiationResponseToReflexList")
);
const RefOPDExamiationResponseToReflexSave = React.lazy(
  () => import("../pages/Examiantion/RefOPDExamiationResponseToReflex/RefOPDExamiationResponseToReflexSave")
);

const SymptomsList = React.lazy(() => import("../pages/Symptoms/SymptomsList"));
const SymptomsSave = React.lazy(() => import("../pages/Symptoms/SymptomsSave"));
const StaffList = React.lazy(() => import("../pages/Team/Staff/StaffList"));
const StaffSave = React.lazy(() => import("../pages/Team/Staff/StaffSave"));
const LeadList = React.lazy(() => import("../pages/Crm/Lead/LeadList"));
const LeadSave = React.lazy(() => import("../pages/Crm/Lead/LeadSave"));
const DistributeList = React.lazy(
  () => import("../pages/Crm/Distribute/DistributeList")
);
const DistributeSave = React.lazy(
  () => import("../pages/Crm/Distribute/DistributeSave")
);
const PatientMasterList = React.lazy(
  () => import("../pages/Patient/PatientMaster/PatientMasterList")
);
const PatientMasterSave = React.lazy(
  () => import("../pages/Patient/PatientMaster/PatientMasterSave")
);
const MedicalRepresentativeSave = React.lazy(
  () =>
    import(
      "../pages/MedicalRepresentative/MedicalRepresentativeMaster/MedicalRepresentativeSave"
    )
);
const MedicalRepresentativeList = React.lazy(
  () =>
    import(
      "../pages/MedicalRepresentative/MedicalRepresentativeMaster/MedicalRepresentativeList"
    )
);
const UsersSave = React.lazy(() => import("../pages/Team/Users/UsersSave"));
const UsersList = React.lazy(() => import("../pages/Team/Users/UsersList"));
const DoctorList = React.lazy(
  () => import("../pages/Doctor/Doctor/DoctorList")
);
const DoctorSave = React.lazy(
  () => import("../pages/Doctor/Doctor/DoctorSave")
);
const AddAppointment = React.lazy(
  () => import("../pages/FrontDesk/AddAppointment/AddAppointment")
);
const Appointments = React.lazy(
  () => import("../pages/FrontDesk/ReceptionAppointments/Appointments")
);
const Certificates = React.lazy(
  () => import("../pages/OPD/Certificates/Certificates")
);
const PatientHistory = React.lazy(
  () => import("../pages/Patient/PatientHistory/PatientHistory")
);
const DoctorSchedule = React.lazy(
  () => import("../pages/Doctor/DoctorSchedule/DoctorSchedule")
);
const OutrichVisits = React.lazy(
  () => import("../pages/Doctor/OutrichVisits/OutrichVisits")
);
const BillingList = React.lazy(
  () => import("../pages/Accounts/Billing/BillingList")
);
const Report = React.lazy(() => import("../pages/Report/Report"));
const BillingSave = React.lazy(
  () => import("../pages/Accounts/Billing/BillingSave")
);
const LanguageSave = React.lazy(
  () => import("../pages/Settings/Language/LanguageSave")
);

const AddMr = React.lazy(() => import("../pages/FrontDesk/AddMr/AddMr"));
const ProcedureTemplate = React.lazy(
  () => import("../pages/Template/ProcedureTemplate/ProcedureTemplate")
);
const CertificatesTemplate = React.lazy(
  () => import("../pages/Template/CertificatesTemplate/CertificatesTemplate")
);
const OPDClinical = React.lazy(
  () => import("../pages/OPD/OPDClinical/OPDClinical")
);
const ProcedureTemplateSave = React.lazy(
  () => import("../pages/Template/ProcedureTemplate/ProcedureTemplateSave")
);
const CertificatesTemplateSave = React.lazy(
  () =>
    import("../pages/Template/CertificatesTemplate/CertificatesTemplateSave")
);
const CertificatesSave = React.lazy(
  () => import("../pages/OPD/Certificates/CertificatesSave")
);
const Profile = React.lazy(() => import("../pages/Profile/Profile"));
const TaxList = React.lazy(
  () => import("../pages/BillingItem/BillingItemMaster/TaxList")
);
const TaxSave = React.lazy(
  () => import("../pages/BillingItem/BillingItemMaster/TaxSave")
);

const SpecialitySave = React.lazy(
  () => import("../pages/Speciality/SpecialitySave")
);
const Speciality = React.lazy(() => import("../pages/Speciality/Speciality"));

const ItemList = React.lazy(() => import("../pages/Item/ItemList"));
const ItemSave = React.lazy(() => import("../pages/Item/ItemSave"));

const RefSymptomAgravatedByList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomAgravatedBy/RefSymptomAgravatedByList")
);

const RefSymptomAgravatedBySave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomAgravatedBy/RefSymptomAgravatedBySave")
);

const RefSymptomRelievedByList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomRelievedBy/RefSymptomRelievedByList")
);

const RefSymptomRelievedBySave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomRelievedBy/RefSymptomRelievedBySave")
);

const RefSymptomDurationUnitList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomDurationUnit/RefSymptomDurationUnitList")
);

const RefSymptomDurationUnitSave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomDurationUnit/RefSymptomDurationUnitSave")
);

const RefSymptomFrequencyUnitList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomFrequencyUnit/RefSymptomFrequencyUnitList")
);

const RefSymptomFrequencyUnitSave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomFrequencyUnit/RefSymptomFrequencyUnitSave")
);
const RefSymptomNatureList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomNature/RefSymptomNatureList")
);

const RefSymptomNatureSave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomNature/RefSymptomNatureSave")
);
const RefSymptomSeverityList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomSeverity/RefSymptomSeverityList")
);

const RefSymptomSeveritySave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomSeverity/RefSymptomSeveritySave")
);
const RefSymptomTimingList = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomTiming/RefSymptomTimingList")
);

const RefSymptomTimingSave = React.lazy(
  () => import("../pages/ChiefComplaint/RefSymptomTiming/RefSymptomTimingSave")
);
const RefPatientFoodItemList = React.lazy(
  () => import("../pages/ChiefComplaint/RefPatientFoodItem/RefPatientFoodItemList")
);

const RefPatientFoodItemSave = React.lazy(
  () => import("../pages/ChiefComplaint/RefPatientFoodItem/RefPatientFoodItemSave")
);

const UnitList = React.lazy(() => import("../pages/Unit/UnitList"));
const UnitSave = React.lazy(() => import("../pages/Unit/UnitSave"));

const CategoryList = React.lazy(() => import("../pages/Category/CategoryList"));
const CategorySave = React.lazy(() => import("../pages/Category/CategorySave"));

const StoreList = React.lazy(() => import("../pages/Store/StoreList"));
const StoreSave = React.lazy(() => import("../pages/Store/StoreSave"));

const MedicineList = React.lazy(() => import("../pages/Medicine/Medicine/MedicineList"));
const MedicineSave = React.lazy(() => import("../pages/Medicine/Medicine/MedicineSave"));

// const RefOPDExamiantionCerebellarSignsList = React.lazy(() => import("../pages/Examiantion/RefOPDExamiantionCerebellarSigns/RefOPDExamiantionCerebellarSignsList"));
// const RefOPDExamiantionCerebellarSignsSave = React.lazy(() => import("../pages/Examiantion/RefOPDExamiantionCerebellarSigns/RefOPDExamiantionCerebellarSignsSave"));


// import Language from "../pages/Settings/Language/Language"

// const CertificatesTemplate = React.lazy(
//   () => import("../pages/Template/CertificatesTemplate/CertificatesTemplate")
// );
// const OPDClinical = React.lazy(
//   () => import("../pages/OPD/OPDClinical/OPDClinical")
// );

export default function RootNavigation() {
  return <RootNavigator />;
}

// function AuthRoute(props: any) {
//     const { userContext, updateUserContext } = React.useContext(PreferencesContext);
//     // if (userContext.userId > 0) {
//     if (userContext.mobileNumber !== "") {
//         return props.children;
//     } else {
//         return <Navigate to="/" replace />;
//     }
// }

function RootNavigator() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <SignInCover />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LoadingFallback />}>
                <SignIn />
              </Suspense>
            }
          />
          <Route
            path="sign-up"
            element={
              <Suspense fallback={<LoadingFallback />}>
                <SignUp />
              </Suspense>
            }
          />
          <Route
            path="thank-You"
            element={
              <Suspense fallback={<LoadingFallback />}>
                <ThankYou />
              </Suspense>
            }
          />
          <Route
            element={
              <Suspense fallback={<LoadingFallback />}>
                <ForgotPassword />
              </Suspense>
            }
            path="forgot-password"
          />
          <Route
            element={
              <Suspense fallback={<LoadingFallback />}>
                <ResetPassword />
              </Suspense>
            }
            path="reset-password"
          />
        </Route>
        <Route
          path="manage-user"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <ManageUser />
            </Suspense>
          }
        />
        <Route path="/" element={<NavBar children={undefined} />}>
          <Route path="dashboard">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Dashboard />
                </Suspense>
              }
            />
          </Route>
          <Route path="users">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <UsersList />
                </Suspense>
              }
            />
            <Route
              path="users-save"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <UsersSave />
                </Suspense>
              }
            />
          </Route>
          <Route path="lead">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LeadList />
                </Suspense>
              }
            />
            <Route
              path="lead-save"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LeadSave />
                </Suspense>
              }
            />
          </Route>
          <Route path="distribute">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <DistributeList />
                </Suspense>
              }
            />
            <Route
              path="distribute-save"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <DistributeSave />
                </Suspense>
              }
            />
          </Route>
          <Route path="consumers">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <ConsumerList />
                </Suspense>
              }
            />
            <Route
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <ConsumerSave />
                </Suspense>
              }
              path="consumer-save"
            />
          </Route>
          <Route path="patient-master">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <PatientMasterList />
                </Suspense>
              }
            />
            <Route
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <PatientMasterSave />
                </Suspense>
              }
              path="patient-master-save"
            />
          </Route>
          <Route path="settings">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route path="organization">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <OrganizationList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <OrganizationSave />
                  </Suspense>
                }
                path="organization-save"
              />
            </Route>
            <Route path="branch">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <BranchList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <BranchSave />
                  </Suspense>
                }
                path="branch-save"
              />
            </Route>
            <Route path="department">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DepartmentList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DepartmentSave />
                  </Suspense>
                }
                path="department-save"
              />
            </Route>
            <Route path="speciality">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <Speciality />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <SpecialitySave />
                  </Suspense>
                }
                path="speciality-save"
              />
            </Route>
            {/* <Route path="ref-opd-examiantion-cerebellar-signs">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefOPDExamiantionCerebellarSignsList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefOPDExamiantionCerebellarSignsSave />
                  </Suspense>
                }
                path="ref-opd-examiantion-cerebellar-signs-save"
              />
            </Route> */}
            <Route
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LanguageSave />
                </Suspense>
              }
              path="language"
            />
          </Route>
          <Route path="help">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Help />
                </Suspense>
              }
            />

          </Route>
          <Route path="master">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Master />
                </Suspense>
              }
            />
            <Route path="investigation-sample-collected-from-list">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InvestigationSampleCollectedFromList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InvestigationSampleCollectedFromSave />
                  </Suspense>
                }
                path="investigation-sample-collected-from-save"
              />
            </Route>
            <Route path="doctor">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DoctorList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DoctorSave />
                  </Suspense>
                }
                path="doctor-save"
              />
            </Route>
            <Route path="item">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <ItemList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <ItemSave />
                  </Suspense>
                }
                path="item-save"
              />
            </Route>
            <Route path="unit">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <UnitList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <UnitSave />
                  </Suspense>
                }
                path="unit-save"
              />
            </Route>
            <Route path="category">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <CategoryList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <CategorySave />
                  </Suspense>
                }
                path="category-save"
              />
            </Route>
            <Route path="store">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <StoreList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <StoreSave />
                  </Suspense>
                }
                path="store-save"
              />
            </Route>
            <Route path="medicine">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineSave />
                  </Suspense>
                }
                path="medicine-save"
              />
            </Route>
            <Route path="medical-representative">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicalRepresentativeList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicalRepresentativeSave />
                  </Suspense>
                }
                path="medical-representative-save"
              />
            </Route>
            <Route path="diagnosis-type">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DiagnosisTypeList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DiagnosisTypeSave />
                  </Suspense>
                }
                path="diagnosis-type-save"
              />
            </Route>
            <Route path="icd-codes">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <ICDCodeList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <ICDCodeSave />
                  </Suspense>
                }
                path="icd-codes-save"
              />
            </Route>
            <Route path="diagnosis-master">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DiagnosisMasterList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DiagnosisMasterSave />
                  </Suspense>
                }
                path="diagnosis-master-save"
              />
            </Route>
            <Route path="medicine-dose">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineDoseList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineDoseSave />
                  </Suspense>
                }
                path="medicine-dose-save"
              />
            </Route>
            <Route path="medicine-group">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineGroupList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineGroupSave />
                  </Suspense>
                }
                path="medicine-group-save"
              />
            </Route>
            <Route path="medicine-route-of-administration">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineRouteOfAdministrationList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineRouteOfAdministrationSave />
                  </Suspense>
                }
                path="medicine-route-of-administration-save"
              />
            </Route>
            <Route path="medicine-relation-to-food">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineRelationtoFoodList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineRelationtoFoodListSave />
                  </Suspense>
                }
                path="medicine-relation-to-food-save"
              />
            </Route>
            <Route path="medicine-indication-for-sos-medicine">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineIndicationforSOSmedicineList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineIndicationforSOSmedicineSave />
                  </Suspense>
                }
                path="medicine-indication-for-sos-medicine-save"
              />
            </Route>
            <Route path="medicine-Generic-name">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineGenericNameList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineGenericNameSave />
                  </Suspense>
                }
                path="medicine-generic-name-save"
              />
            </Route>
            <Route path="medicine-company">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicinecompanyList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicinecompanySave />
                  </Suspense>
                }
                path="medicine-company-save"
              />
            </Route>
            <Route path="medicine-formulation">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineFormulationList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineFormulationSave />
                  </Suspense>
                }
                path="medicine-formulation-save"
              />
            </Route>
            <Route path="medicine-used-as">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineUsedAsList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineUsedAsSave />
                  </Suspense>
                }
                path="medicine-used-as-save"
              />
            </Route>
            <Route path="medicine-diluent-name">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineDiluentNameList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineDiluentNameSave />
                  </Suspense>
                }
                path="medicine-diluent-name-save"
              />
            </Route>
            <Route path="investigation-master">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InvestigationMasterList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InvestigationMasterSave />
                  </Suspense>
                }
                path="investigation-master-save"
              />
            </Route>
            <Route path="followup-duration">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <FollowUpDurationList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <FollowUpDurationSave />
                  </Suspense>
                }
                path="followup-duration-save"
              />
            </Route>
            <Route path="followup-reason">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <FollowUpReasonList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <FollowUpReasonSave />
                  </Suspense>
                }
                path="followup-reason-save"
              />
            </Route>
            <Route path="followup-instruction">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <FollowUpInstructionList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <FollowUpInstructionSave />
                  </Suspense>
                }
                path="followup-instruction-save"
              />
            </Route>
            <Route path="diagnosis-template">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DiagnosisTemplateList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <DiagnosisTemplateSave />
                  </Suspense>
                }
                path="diagnosis-template-save"
              />
            </Route>

            <Route path="medicine-template">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineTemplateList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineTemplateSave />
                  </Suspense>
                }
                path="medicine-template-save"
              />
            </Route>
            <Route path="investigation-template">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InvestigationTemplateList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InvestigationTemplateSave />
                  </Suspense>
                }
                path="investigation-template-save"
              />
            </Route>
            <Route path="medicine-template">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineTemplateList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <MedicineTemplateSave />
                  </Suspense>
                }
                path="medicine-template-save"
              />
            </Route>

            <Route path="laboratory">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <LaboratoryList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <LaboratorySave />
                  </Suspense>
                }
                path="laboratory-save"
              />
            </Route>

            <Route path="hospital">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <HospitalList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <HospitalSave />
                  </Suspense>
                }
                path="hospital-save"
              />
            </Route>
            <Route path="ref-opd-examination-cerebellar-signs">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefOPDExaminationCerebellarSignsList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefOPDExaminationCerebellarSignsSave />
                  </Suspense>
                }
                path="ref-opd-examination-cerebellar-signs-save"
              />
            </Route>
            <Route path="ref-opd-examination-response-to-reflex">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefOPDExamiationResponseToReflexList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefOPDExamiationResponseToReflexSave />
                  </Suspense>
                }
                path="ref-opd-examination-response-to-reflex-save"
              />
            </Route>


            <Route path="symptoms">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <SymptomsList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <SymptomsSave />
                  </Suspense>
                }
                path="symptoms-save"
              />
            </Route>
            <Route path="symptom-agravated-by">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomAgravatedByList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomAgravatedBySave />
                  </Suspense>
                }
                path="symptom-agravated-by-save"
              />
            </Route>
            <Route path="symptom-relieved-by">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomRelievedByList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomRelievedBySave />
                  </Suspense>
                }
                path="symptom-relieved-by-save"
              />
            </Route>
            <Route path="symptom-duratio-unit">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomDurationUnitList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomDurationUnitSave />
                  </Suspense>
                }
                path="symptom-duratio-unit-save"
              />
            </Route>
            <Route path="symptom-frequency-unit">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomFrequencyUnitList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomFrequencyUnitSave />
                  </Suspense>
                }
                path="symptom-frequency-unit-save"
              />
            </Route>
            <Route path="symptom-nature">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomNatureList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomNatureSave />
                  </Suspense>
                }
                path="symptom-nature-save"
              />
            </Route>
            <Route path="symptom-severity">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomSeverityList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomSeveritySave />
                  </Suspense>
                }
                path="symptom-severity-save"
              />
            </Route>

            <Route path="symptom-timing">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomTimingList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefSymptomTimingSave />
                  </Suspense>
                }
                path="symptom-timing-save"
              />
            </Route>
            <Route path="patient-food-item">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefPatientFoodItemList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <RefPatientFoodItemSave />
                  </Suspense>
                }
                path="patient-food-item-save"
              />
            </Route>
            <Route path="tax">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <TaxList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <TaxSave />
                  </Suspense>
                }
                path="tax-save"
              />
            </Route>
            <Route path="procedure-template">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <ProcedureTemplate />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <ProcedureTemplateSave />
                  </Suspense>
                }
                path="procedure-template-save"
              />
            </Route>
            <Route path="certificates-template">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <CertificatesTemplate />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <CertificatesTemplateSave />
                  </Suspense>
                }
                path="certificates-template-save"
              />
            </Route>

            <Route path="instruction">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InstructionList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <InstructionSave />
                  </Suspense>
                }
                path="instruction-save"
              />
            </Route>

            <Route path="quick-notes">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <QuickNotesList />
                  </Suspense>
                }
              />
              <Route
                element={
                  <Suspense fallback={<LoadingFallback />}>
                    <QuickNotesSave />
                  </Suspense>
                }
                path="quick-notes-save"
              />
            </Route>

          </Route>
          <Route path="staff">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <StaffList />
                </Suspense>
              }
            />
            <Route
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <StaffSave />
                </Suspense>
              }
              path="staff-save"
            />
          </Route>
          <Route path="certificates">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Certificates />
                </Suspense>
              }
            />
            <Route
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <CertificatesSave />
                </Suspense>
              }
              path="certificates-save"
            />
          </Route>
          <Route path="billing">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <BillingList />
                </Suspense>
              }
            />
            <Route
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <BillingSave />
                </Suspense>
              }
              path="billing-save"
            />
          </Route>
          <Route path="opd-clinical">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <OPDClinical />
                </Suspense>
              }
            />
          </Route>
          <Route path="add-mr">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <OPDClinical />
                </Suspense>
              }
            />
          </Route>
          <Route path="patient-save">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <AddMr />
                </Suspense>
              }
            />
          </Route>
          <Route path="add-appointment">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <AddAppointment />
                </Suspense>
              }
            />
          </Route>
          <Route path="appointments">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Appointments />
                </Suspense>
              }
            />
          </Route>
          <Route path="patient-history">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <PatientHistory />
                </Suspense>
              }
            />
          </Route>
          <Route path="profile">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Profile />
                </Suspense>
              }
            />
          </Route>
          <Route path="doctor-schedule">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <DoctorSchedule />
                </Suspense>
              }
            />
          </Route>
          <Route path="outreach-visits">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <OutrichVisits />
                </Suspense>
              }
            />
          </Route>
          <Route path="report">
            <Route
              index
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Report />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
