import React, { useContext } from "react";
import { Context } from "../models/Context";
import {
  getBuildNumber,
  getStorage,
  getVersion,
  setBuildNumber,
  setStorage,
  setVersion,
} from "./storage";

let token = "";

export const POST = (
  url: string,
  data: any = null,
  isFormData: boolean = false,
  fileToken: string = ""
) => {
  return new Promise((resolve, reject) => {
    let localStorage = getStorage();
    let curVersion = getVersion();
    let buildNumber = getBuildNumber();

    if (localStorage?.loginDetail) {
      token =
        localStorage &&
        (localStorage?.loginDetail.token
          ? localStorage.loginDetail?.token
          : "");
    } else {
      token = "";
    }

    let options = {
      headers: {},
    };

    if (isFormData === true) {
      options.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "*/*",
      };
    } else {
      options.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    let postOptions = { ...options.headers };

    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }

    return fetch(url, {
      method: "POST",
      headers: postOptions,
      body: isFormData === false ? JSON.stringify(data) : data,
    })
      .then((response) =>
        response.json().then((body) => {
          return {
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            header: response.headers,
            data: body,
          };
        })
      )
      .then((responseJson) => {
        let apiVersion = responseJson.header.get("X-Version")!.toString();
        let apiBuildNumber = responseJson.header
          .get("X-Buildnumber")!
          .toString();
        let isLogout = responseJson.header.get("X-Access")!.toString();
        if (
          curVersion !== null &&
          curVersion !== undefined &&
          curVersion !== "" &&
          buildNumber !== null &&
          buildNumber !== undefined &&
          buildNumber !== ""
        ) {
          if (apiVersion !== curVersion || apiBuildNumber !== buildNumber) {
            setVersion(responseJson.header.get("X-Version")!.toString());
            setBuildNumber(
              responseJson.header.get("X-Buildnumber")!.toString()
            );

            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });

            window.location.reload();

            if (isLogout === "true") {
              //remove local storage
              let curuserInfo: Context = new Context();
              setStorage(curuserInfo);
            }
          }
        } else {
          setVersion(apiVersion);
          setBuildNumber(apiBuildNumber);
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          window.location.reload();
        }
        if (!responseJson.ok) {
          if (responseJson.status === 400) {
            //400 = bad request
            if (responseJson.data && responseJson.data.message)
              throw responseJson.data.message;
            else throw responseJson.statusText;
          } else throw responseJson.statusText;
        } else resolve(responseJson.data);
      })
      .catch((error) => {
        // window.location.href = MAINTENANCE_URL;
        reject(error);
      });
  });
};

export const GET = (url: any, params: any) => {
  return new Promise((resolve, reject) => {
    let localStorage = getStorage();
    let curVersion = getVersion();
    let buildNumber = getBuildNumber();

    if (localStorage?.loginDetail) {
      token =
        localStorage &&
        (localStorage?.loginDetail.token
          ? localStorage?.loginDetail.token
          : "");
    } else {
      token = "";
    }

    let options = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    
    return fetch(url, { method: "GET", ...options })
      .then((response) =>
        response.json().then((body) => ({
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          header: response.headers,
          data: body,
        }))
      )
      .then((responseJson) => {
        let apiVersion = responseJson.header.get("X-Version")!.toString();
        let isLogout = responseJson.header.get("X-Access")!.toString();
        let apiBuildNumber = responseJson.header
          .get("X-Buildnumber")!
          .toString();
        if (
          curVersion !== null &&
          curVersion !== undefined &&
          curVersion !== "" &&
          buildNumber !== null &&
          buildNumber !== undefined &&
          buildNumber !== ""
        ) {
          if (apiVersion !== curVersion || apiBuildNumber !== buildNumber) {
            setVersion(responseJson.header.get("X-Version")!.toString());
            setBuildNumber(
              responseJson.header.get("X-Buildnumber")!.toString()
            );

            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });

            window.location.reload();

            if (isLogout === "true") {
              //remove local storage
              let curuserInfo: Context = new Context();
              setStorage(curuserInfo);
            }
          }
        } else {
          setVersion(apiVersion);
          setBuildNumber(apiBuildNumber);
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          window.location.reload();
        }
        resolve(responseJson);

        if (!responseJson.ok) {
          if (responseJson.status === 400) {
            //400 = bad request
            if (responseJson.data && responseJson.data.message)
              throw responseJson.data.message;
            else throw responseJson.statusText;
          } else throw responseJson.statusText;
        } else resolve(responseJson.data);
      })
      .catch((error) => {
        // window.location.href = MAINTENANCE_URL;
        reject(error);
      });
  });
};
